import Features, {FeatureText} from '../components/Features';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import PricingTable from '../components/PricingTable';
import PropTypes from 'prop-types';
import React from 'react';
import Seo from '../components/Seo';
import onset from '../assets/images/onset.jpg';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  Text,
  useBreakpointValue
} from '@chakra-ui/react';
import {
  CONTAINER_PADDING_X,
  CONTAINER_PADDING_Y,
  CONTAINER_SPACING
} from '../utils';
import {FaDiscord} from 'react-icons/fa';
import {FiArrowRight} from 'react-icons/fi';
import {Link as GatsbyLink} from 'gatsby';

function Price({title, cost, children}) {
  return (
    <>
      <Heading size="xl">{title}</Heading>
      <Heading as="h3" mb="3" fontWeight="semibold" size="lg">
        {cost}
      </Heading>
      <Text>{children}</Text>
    </>
  );
}

Price.propTypes = {
  title: PropTypes.string.isRequired,
  cost: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

const tagline = 'Create video treatments and play music in one place.';
const description = `${tagline} Separate your song into blocks with custom start and end times to save time scrubbing on set.`;

export default function Index() {
  const buttonSize = useBreakpointValue({base: 'sm', md: 'md'});
  const largeButtonSize = useBreakpointValue({md: 'lg'});

  return (
    <Layout>
      <Seo
        title="A modern music video workflow"
        tagline={tagline}
        description={description}
      />
      <Hero
        buttonSize={largeButtonSize}
        description={`${description} Select a block and start shooting!`}
      />
      <Features buttonSize={buttonSize} />
      <Box
        h={CONTAINER_PADDING_Y}
        css={({theme}) => {
          const gray = theme.colors.gray[50];
          return {
            backgroundImage: `linear-gradient(${[
              'to bottom left',
              gray,
              `${gray} 50%`,
              'transparent 50%'
            ]})`
          };
        }}
      />
      <Stack
        maxW="container.xl"
        mx="auto"
        mt={CONTAINER_PADDING_Y.map(p => p * -1)}
        px={CONTAINER_PADDING_X}
        pb={CONTAINER_PADDING_Y}
        spacing={CONTAINER_SPACING}
      >
        <div id="pricing">
          <Box mx="auto" maxW="container.sm" textAlign="center">
            <FeatureText
              isLarge
              title="Fair pricing"
              description={
                <>
                  Playback uses a <strong>pay-per-project</strong> system. No
                  monthly subscription or extra fees. After you purchase a
                  project slot, it&apos;s yours forever.
                </>
              }
            />
          </Box>
        </div>
        <PricingTable />
        <Box textAlign="center">
          <ButtonGroup size={largeButtonSize} spacing="4">
            <Button rounded="full" as={GatsbyLink} to="/guide">
              Read the guide
            </Button>
            <Button
              rounded="full"
              colorScheme="pink"
              rightIcon={<FiArrowRight />}
              as={GatsbyLink}
              to="/projects"
            >
              Try Playback
            </Button>
          </ButtonGroup>
        </Box>
        <Flex
          minH="lg"
          px={CONTAINER_PADDING_X}
          py={CONTAINER_PADDING_Y}
          bgImage={`url(${onset})`}
          bgSize="cover"
          bgPos="center"
          pos="relative"
        >
          <Box w={{md: '50%'}} mt="auto" color="white">
            <FeatureText
              isLarge
              title="A Discord for music video filmmakers"
              description="Join our community to connect with creators from all kinds of backgrounds. Share your work and ask questions. The Playback team is there to help!"
            />
            <Button
              size={buttonSize}
              rounded="full"
              colorScheme="discord"
              href={process.env.GATSBY_DISCORD_URL}
              as="a"
              leftIcon={<Box as={FaDiscord} fontSize="2xl" />}
            >
              Join our community
            </Button>
          </Box>
        </Flex>
      </Stack>
      <Footer />
    </Layout>
  );
}
