import PropTypes from 'prop-types';
import React from 'react';
import {Heading, Text, chakra, useTheme} from '@chakra-ui/react';
import {graphql, useStaticQuery} from 'gatsby';

function Price({title, cost, children}) {
  return (
    <>
      <Heading size="xl">{title}</Heading>
      <Heading as="h3" mb="3" fontWeight="semibold" size="lg">
        {cost}
      </Heading>
      <Text>{children}</Text>
    </>
  );
}

Price.propTypes = {
  title: PropTypes.string.isRequired,
  cost: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default function PricingTable() {
  const {colors} = useTheme();
  const {config} = useStaticQuery(
    graphql`
      {
        config {
          freeProjectLimit
          freeFileSizeLimit
          paidFileSizeLimit
          paidProjectCost
        }
      }
    `
  );
  return (
    <chakra.table w="full" textAlign="center" sx={{tableLayout: 'fixed'}}>
      <chakra.thead
        sx={{
          td: {
            py: 8,
            px: 4,
            borderBottomWidth: '1px'
          }
        }}
      >
        <tr>
          <td />
          <chakra.td borderLeftWidth="1px">
            <Price title="Trial" cost="Free">
              Take Playback for a spin. Limit {config.freeProjectLimit} per
              account.
            </Price>
          </chakra.td>
          <chakra.td
            color="white"
            roundedTop="2xl"
            bgImage={`linear-gradient(${[
              'to top left',
              colors.pink[500],
              colors.indigo[300]
            ]})`}
          >
            <Price title="Pro" cost={`$${config.paidProjectCost} one time`}>
              The clear choice for pro filmmakers.
            </Price>
          </chakra.td>
        </tr>
      </chakra.thead>
      <chakra.tbody
        sx={{
          td: {
            pt: 8,
            fontSize: {
              sm: 'lg',
              md: 'xl'
            },
            borderRightWidth: '1px',
            '&:first-of-type': {
              fontFamily: 'heading',
              fontWeight: 'medium',
              textAlign: 'left'
            }
          }
        }}
      >
        <tr>
          <td>Max. upload file size</td>
          <td>{config.freeFileSizeLimit} MB</td>
          <td>{config.paidFileSizeLimit} MB</td>
        </tr>
        <tr>
          <td>Collaboration tools</td>
          <td>No</td>
          <td>Yes</td>
        </tr>
        <chakra.tr
          sx={{
            td: {
              pb: 8,
              borderBottomWidth: '1px'
            }
          }}
        >
          <td>Download project as PDF</td>
          <td>No</td>
          <td>Yes</td>
        </chakra.tr>
        <tr>
          <chakra.td h="12" />
          <td />
          <td />
        </tr>
      </chakra.tbody>
    </chakra.table>
  );
}
