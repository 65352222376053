import PropTypes from 'prop-types';
import React from 'react';
import pdf from '../assets/images/pdf2.png';
import {
  AspectRatio,
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Img,
  SimpleGrid,
  Square,
  Stack,
  Text
} from '@chakra-ui/react';
import {
  CONTAINER_GAP,
  CONTAINER_PADDING_X,
  CONTAINER_PADDING_Y,
  CONTAINER_SPACING
} from '../utils';
import {ReactComponent as CassetteIcon} from '../assets/icons/cassette.svg';
import {
  FiDownload,
  FiLock,
  FiUserPlus,
  FiWatch,
  FiWifiOff
} from 'react-icons/fi';
import {ReactComponent as MusicIcon} from '../assets/icons/music.svg';
import {ReactComponent as ScissorsIcon} from '../assets/icons/scissors.svg';

export function FeatureText({isLarge, title, description}) {
  return (
    <>
      <Heading
        mb={isLarge ? {base: 3, md: 4} : {base: 1, md: 2}}
        fontWeight={isLarge ? 'bold' : 'semibold'}
        size={isLarge ? '2xl' : 'xl'}
      >
        {title}
      </Heading>
      <Text
        fontSize={isLarge ? {base: 'lg', md: 'xl'} : {md: 'lg'}}
        sx={{':not(:last-child)': {mb: 6}}}
      >
        {description}
      </Text>
    </>
  );
}

FeatureText.propTypes = {
  isLarge: PropTypes.bool,
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired
};

function Feature({number, icon, title, description, ...props}) {
  return (
    <Flex
      direction="column"
      p={{base: 6, md: 8}}
      borderColor="currentColor"
      borderWidth="2px"
      rounded="lg"
      bgColor="white"
      {...props}
    >
      <Text mb={{base: 6, md: 8}} fontSize="sm" fontFamily="mono">
        {number.toString().padStart(2, '0')}.
      </Text>
      <Box
        mt="auto"
        as={icon}
        boxSize={{base: 14, md: 16}}
        fill="currentColor"
        mb={{base: 3, md: 4}}
      />
      <Box color="gray.800">
        <FeatureText title={title} description={description} />
      </Box>
    </Flex>
  );
}

Feature.propTypes = {
  icon: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired
};

function FeatureIcon(props) {
  return (
    <Square
      fontSize={{base: 'xl', md: '2xl'}}
      rounded={{base: 'xl', md: '2xl'}}
      size={{base: 10, md: 12}}
      mb={{base: 3, md: 4}}
      color="white"
      mx="auto"
      {...props}
    />
  );
}

export default function Features({buttonSize}) {
  return (
    <Box
      css={({theme}) => ({
        backgroundImage: `linear-gradient(${['white', theme.colors.gray[50]]})`
      })}
    >
      <Stack
        spacing={CONTAINER_SPACING}
        maxW="container.xl"
        mx="auto"
        px={CONTAINER_PADDING_X}
        pt={CONTAINER_PADDING_Y}
        pb={CONTAINER_PADDING_Y.map(p => p * 2)}
      >
        {/* TODO: use templateAreas for this */}
        <Grid gap={CONTAINER_GAP}>
          <AspectRatio
            ratio={16 / 9}
            gridColumn={{md: '2 / 4'}}
            gridRow={{md: '1 / 3'}}
            bgColor="black"
          >
            <Box
              as="iframe"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/VCk9C-Rry-w?rel=0&modestbranding=1"
              frameBorder="0"
              allowFullScreen
            />
          </AspectRatio>
          <Feature
            number={1}
            color="indigo.500"
            gridColumn={{md: '1 / 2'}}
            gridRow={{md: '1 / 2'}}
            title="Upload your music"
            icon={CassetteIcon}
            description="When you create a new project, you upload the song that the music video will be set to."
          />
          <Feature
            number={2}
            color="purple.500"
            gridColumn={{md: '1 / 2'}}
            gridRow={{md: '2 / 4'}}
            title="Slice it up"
            icon={ScissorsIcon}
            description="Divide your song into smaller timecode blocks. Add notes and images."
          />
          <Feature
            number={3}
            color="pink.500"
            gridColumn={{md: '2 / 4'}}
            gridRow={{md: '3 / 4'}}
            title="Play what you need"
            icon={MusicIcon}
            description="Whether you're in pre-production or filming on set, simply choose a block or play the full song."
          />
        </Grid>
        <Box textAlign="center">
          <Box textStyle="smallCaps">Essential features</Box>
          <SimpleGrid
            alignItems="center"
            py="8"
            maxW="container.lg"
            mx="auto"
            columns={{md: 2}}
            spacing={CONTAINER_PADDING_X}
          >
            <div>
              <FeatureIcon bgColor="yellow.300">
                <FiLock />
              </FeatureIcon>
              <FeatureText
                title="For your ears only"
                description="Your audio files are securely hosted on Amazon Web Services (AWS). Only you and your collaborators can access them."
              />
            </div>
            <div>
              <FeatureIcon bgColor="indigo.300">
                <FiUserPlus />
              </FeatureIcon>
              <FeatureText
                title="Collaborate with others"
                description="Share your project with a team member. You choose whether they can pitch in and make edits or just view your work."
              />
            </div>
            <div>
              <FeatureIcon bgColor="teal.300">
                <FiWatch />
              </FeatureIcon>
              <FeatureText
                title="Adjust playback rate"
                description="Slow down or speed up the song for easy slow-mo (or fast-mo) lip syncing."
              />
            </div>
            <Box color="gray.300">
              <FeatureIcon bgColor="gray.300">
                <FiWifiOff />
              </FeatureIcon>
              <FeatureText title="Offline access" description="Coming soon" />
            </Box>
          </SimpleGrid>
        </Box>
        <Box
          display={{md: 'grid'}}
          gridTemplateColumns="repeat(2, 1fr)"
          rounded="lg"
          gridColumn="3 / 4"
          gridRow="4 / 5"
          pos="relative"
          zIndex="1"
          css={({theme}) => ({
            backgroundImage: `linear-gradient(${[
              '135deg',
              theme.colors.gray[100],
              theme.colors.gray[300]
            ]})`
          })}
        >
          <Flex
            align="flex-start"
            direction="column"
            px={[6, 8, 10]}
            py={[8, 10, 12]}
          >
            <Badge
              mb="auto"
              fontSize={{
                base: 'xs',
                md: 'sm'
              }}
              colorScheme="indigo"
              variant="outline"
            >
              Pro feature
            </Badge>
            <Box mt={CONTAINER_PADDING_X}>
              <FeatureText
                isLarge
                title="Communicate your vision. Win pitches."
                description="Download your project as a PDF. It looks great on mobile, or you can print it out for quick reference on set."
              />
              <Button
                size={buttonSize}
                rounded="full"
                colorScheme="indigo"
                leftIcon={<FiDownload />}
                href="/sample.pdf"
                as="a"
                target="_blank"
              >
                Download sample
              </Button>
            </Box>
          </Flex>
          <Img
            display={{
              base: 'none',
              md: 'block'
            }}
            mt="-12"
            mb="-24"
            src={pdf}
          />
        </Box>
      </Stack>
    </Box>
  );
}

Features.propTypes = {
  buttonSize: PropTypes.string
};
